import { Decimal } from 'decimal.js'

// const DecimalFormatCustomFix = (v, fix = 2) => {
//   // if (v === undefined || v === null) return '';
//   // return parseFloat(Math.round(v * 100) / 100).toFixed(2);

//   if (v === undefined || v === null) return '';
//   const totalNum = parseFloat(Math.round(v * 100) / 100)

//   // return totalNum.toFixed(2)
//   // return totalNum.toLocaleString("en-US");
//   return totalNum.toFixed(fix).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// };

const DecimalNumber = (v, fix = 2) => {
  try {
    return new Decimal(v).toFixed(fix, Decimal.ROUND_HALF_UP)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } catch (error) {
    return v
  }
};

const DecimalFormatCustomFix = (v, fix = 2) => DecimalNumber(v, fix)
const DecimalFormat = v => DecimalNumber(v, 2)

// eslint-disable-next-line import/prefer-default-export
export { DecimalFormat, DecimalFormatCustomFix }
